import React from "react";

export default function Staking_program() {
  return (
    <>
      <section className="section easy position-relative" id="Whitepaper" style={{backgroundImage:`url('./assets/images/bg_02.png')`, backgroundRepeat:"no-repeat", backgroundSize:"cover", backgroundPosition:"50% 50%"}}>
        <div className="container">
          <div className="row gaper align-items-center">
            <div className="col-12 col-lg-6 col-xl-5 offset-xl-2">
              <div className="section__content">
                <span className="sub-title-two">Impresive Token Staking Program</span>
                <h2 className="title title-animation">Staking Program</h2>
                <p>
                  Impresive token staking program gives you 2x rewards on your staking. 
                </p>
                <div className="section__content-cta">
                  <a href="https://theimpresive.com/media/impresive_staking.pdf" target="_blank" className="btn btn--primary">
                    Download
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-5">
              <div className="easy__thumb dir-rtl reveal-img d-block parallax-img">
                <img
                  src="assets/images/whitepaper.png"
                  alt="Image"
                  className="unset"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
