import React, { useContext, useEffect, useState } from "react";
import { ImpC } from "../../Myontext";

export default function DashboardStatusics() {
  const {
    smuser,
  } = useContext(ImpC);
  const [counter, setcounter] = useState(0);

  useEffect(() => {
    if (smuser === null) {
      return
    }
    // console.log("data[0].starttimedata[0].starttimedata[0].starttime", data);
    // console.log("data[0].dailyreward",data[0].dailyreward,Number(data[0].duration));
    const intervalId = setInterval(() => {
      const firstPaymentDate = new Date(Number(smuser._lastclaim) * 1000); //replace with your first payment date
      // const firstPaymentDate = new Date(Number(1713831703) * 1000); //replace with your first payment date
      const lsta = Number(smuser.d_reward);
      const aw = (lsta / 86400).toFixed(14);
      const currentDate = new Date();
      const timePassed = currentDate.getTime() - firstPaymentDate.getTime();
      const totalSeconds = Math.floor(timePassed / 1000);
      const totalAmount = totalSeconds * aw; //replace with your payment amount per second
      // const tilldate = Number(1713918103)* 1000 + 24 * 60 * 60 * 1000;
      const tilldate = Number(smuser._lastclaim) * 1000 + 24 * 60 * 60 * 1000;
      const time = Date.parse(new Date(tilldate)) - Date.parse(new Date());
      // console.log("aw",aw);
      if (Math.sign(time) === -1 || time.toString() === "NaN") {
        // const currentDate = new Date(Number(1713918103) * 1000);
        const currentDate = new Date(tilldate);
        const timePassed = currentDate.getTime() - firstPaymentDate.getTime();
        const totalSeconds = Math.floor(timePassed / 1000);
        const totalAmount = totalSeconds * aw; //replace with your payment amount per second
        setcounter(totalAmount);
        // dataa.push(totalAmount)

      } else {
        // dataa.push(totalAmount)
        setcounter(totalAmount);
      }


      // setcounter(dataa);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [smuser]);
  return (
    <>
      <div className="row my-3">
        {/* <h4 className="pb-2">Savings</h4> */}
        {/* <div className="col-lg-3 col-md-12 col-sm-12 col-12 mt-2">
          <div className="card border border-gradient border-gradient-green-2">
            <div className="card-body d-flex flex-wrap justify-content-between align-items-center">
              <div>
                <img
                  src="./assets/images/four.png"
                  width="50"
                  alt=""
                  srcset=""
                />
              </div>
              <div className="text-end">
                <p className="card-text text-green fw-bold">Pending Reward</p>
                <h2 className="card-title text-white fw-bold">{counter} IMP</h2>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt-2">
          <div className="card border border-gradient border-gradient-green-2">
            <div className="card-body d-flex flex-wrap justify-content-between align-items-center">
              <div>
                <img
                  src="./assets/images/four.png"
                  width="50"
                  alt=""
                  srcset=""
                />
              </div>
              <div className="text-end">
                <p className="card-text text-green fw-bold">Reward Token</p>
                <h2 className="card-title text-white fw-bold">{smuser !== null ? smuser._locked : 0} IMP</h2>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt-2">
          <div className="card border border-gradient border-gradient-green-2">
            <div className="card-body d-flex flex-wrap justify-content-between align-items-center">
              <div>
                <img
                  src="./assets/images/four.png"
                  width="50"
                  alt=""
                  srcset=""
                />
              </div>
              <div className="text-end">
                <p className="card-text text-white fw-bold">Reward IMP</p>
                <h2 className="card-title text-white fw-bold">{smuser!==null ? smuser._locked:0} IMP</h2>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt-2">
          <div className="card border border-gradient border-gradient-green-2">
            <div className="card-body d-flex flex-wrap justify-content-between align-items-center">
              <div>
                <img
                  src="./assets/images/four.png"
                  width="50"
                  alt=""
                  srcset=""
                />
              </div>
              <div className="text-end">
                <p className="card-text text-green fw-bold">Claimed Token</p>
                <h2 className="card-title text-white fw-bold">{smuser !== null ? smuser._claimed : 0} IMP</h2>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt-2">
          <div className="card border border-gradient border-gradient-green-2">
            <div className="card-body d-flex flex-wrap justify-content-between align-items-center">
              <div>
                <img
                  src="./assets/images/four.png"
                  width="50"
                  alt=""
                  srcset=""
                />
              </div>
              <div className="text-end">
                <p className="card-text text-white fw-bold">Claimed $</p>
                <h2 className="card-title text-white fw-bold">${smuser!==null ? smuser._claimed * rate:0} </h2>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt-2">
          <div className="card border border-gradient border-gradient-green-2">
            <div className="card-body d-flex flex-wrap justify-content-between align-items-center">
              <div>
                <img
                  src="./assets/images/four.png"
                  width="50"
                  alt=""
                  srcset=""
                />
              </div>
              <div className="text-end">
                <p className="card-text text-white fw-bold">Referral reward</p>
                <h2 className="card-title text-white fw-bold">${smuser!==null ? smuser._referalreward:0} </h2>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt-2">
          <div className="card border border-gradient border-gradient-green-2">
            <div className="card-body d-flex flex-wrap justify-content-between align-items-center">
              <div>
                <img
                  src="./assets/images/four.png"
                  width="50"
                  alt=""
                  srcset=""
                />
              </div>
              <div className="text-end">
                <p className="card-text text-green fw-bold">Referral Reward</p>
                <h2 className="card-title text-white fw-bold">{smuser !== null ? smuser._referalreward : 0} IMP</h2>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </>
  );
}
