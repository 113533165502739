import logo from './logo.svg';
import './App.css';
import Index from './Components/Home/Index';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from './Components/Dashboard/Dashboard';
import ComingSoon from './Components/ComingSoon';
import Locked from './Components/Dashboard/Locked';
import ReferralReward from './Components/Dashboard/ReferralReward';
import Claimed from './Components/Dashboard/Claimed';
import Overview from './Components/Dashboard/Overview';
import DailyReport from './Components/Dashboard/DailyReport';

function App() {
  return (
    <>
      {/* <Index /> */}
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="/locked" element={<Locked />} />
        <Route path="/referral-reward" element={<ReferralReward />} />
        <Route path="/claimed" element={<Claimed />} />
        <Route path="/overview" element={<Overview />} />
        <Route path="/daily-reward" element={<DailyReport />} />
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
