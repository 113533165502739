import React, { useContext, useEffect, useState } from "react";
import Header2 from "../comman/Header2";
import Sidebar from "../comman/Sidebar";
import { ImpC } from "../../Myontext";

export default function DailyReport() {
  const {
    account, smuser,
    ethers, formatAddress,
    copyaddress, formatDate,
    locked, stakcontract
  } = useContext(ImpC);
  const [datas, setdatas] = useState([]);

  function cdays(timestamp) {
    const currentDate = new Date();
    const startDate = new Date(timestamp * 1000);

    const differenceInTime = currentDate.getTime() - startDate.getTime();
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays
  }
  const getData = async () => {
    if (!stakcontract || !account || !smuser) {
      return
    }
    const lsta = Number(smuser.d_reward);
    // const aw = (lsta / 86400).toFixed(14);
    let data = await stakcontract.getuserstakings(account);
    var fdata = []
    for (let index = 0; index < data.length; index++) {
      const e = data[index];
      var t_days = cdays(Number(e._sdate))
      var t_re = t_days * lsta 
      // var t_re = 980
      if (t_re >= Number(ethers.utils.formatUnits(e._token, 18))) {
        t_re = Number(ethers.utils.formatUnits(e._token, 18))
      }
      fdata.push({
        _amt: Number(ethers.utils.formatUnits(e._amt, 18)),
        _rate: Number(ethers.utils.formatUnits(e._rate, 18)),
        _sdate: Number(e._sdate),
        _edate: Number(e._edate),
        _token: Number(ethers.utils.formatUnits(e._token, 18)),
        _lid: e._lid,
        t_days: t_days,
        t_reward: t_re

      })
    }
    setdatas(fdata)
  }
  useEffect(() => {
    getData()
  }, [stakcontract, smuser, account])
  return (
    <>
      <div className="d-block" id="wrapper" >
        <Sidebar />
        <div id="smooth-wrapper">
          <div id="smooth-content">
            <Header2 />
            <main className="">
              <div className="container">

                {/* ==== cart start ==== */}
                <section className="cart-m pt-5">
                  <div className="container">
                    <div class="card-shadow">
                      <div class="card__content-shadow">

                        <div className="row">
                          <div className="col-12 ">
                            <div className="cart-m-inner">
                              <div className="cart__wrapper-footer align-items-center my-2">
                                <div className="intro">
                                  <h2 className="light-title title-animation fw-7 text-white mt-12">
                                    Daily Reward
                                  </h2>
                                </div>
                                {/* <form>
                                    <input
                                      type="text"
                                      name="promo-code"
                                      id="promoCode"
                                      placeholder="Promo code"
                                    />
                                    <button
                                      type="button"
                                      className="btn btn--secondary"
                                    >
                                      <i
                                        class="bi bi-search"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </form> */}
                              </div>
                              <div className="cart-t-wrapper">
                                <table>
                                  <thead>
                                    <tr>
                                      <th className="text-green">#</th>
                                      {/* <th className="text-green">ID</th> */}
                                      <th className="text-green">Start Date</th>
                                      <th className="text-green">Stake Token</th>
                                      <th className="text-green">Days</th>
                                      <th className="text-green">Reward Token</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {datas.map((item, i) => {
                                      return (
                                        <tr className="cart-item-single-m">
                                          <td className="text-white">
                                            {i + 1}
                                          </td>
                                          <td className="text-white">
                                            {formatDate(item._sdate * 1000)}
                                          </td>
                                          {/* <td className="text-white">
                                            {formatAddress(item._lid)}
                                          </td> */}
                                          <td className="text-white">
                                            {item._token} IMP
                                          </td>
                                          <td className="text-white">
                                            {item.t_days}
                                          </td>
                                          <td className="text-white">
                                            {item.t_reward} IMP
                                          </td>

                                        </tr>
                                      )
                                    })}

                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div >
            </main >
          </div >
        </div >
      </div>
    </>
  );
}
