import React, { useContext, useState } from "react";
import "./styles.css";
import { ImpC } from "../../Myontext";
import { useNavigate } from "react-router-dom";

export default function Overview() {

  const { setaccount, toastError, toastSuccess } = useContext(ImpC);
  const [addr, setaddr] = useState('')
  const navigate = useNavigate();

  function go() {
    if (!addr) {
      toastError("Enter address")
      return
    }
    setaccount(addr)
    toastSuccess("Here you go!!")
    navigate("/dashboard");
  }
  return (
    <>
      <div className="d-block" id="wrapper" >
        <div id="smooth-wrapper">
          <div id="smooth-content">

            <main className="">
              <div className="container pb-5 ">

                <div className="row justify-content-center my-3">

                  <div className="col-12 d-flex flex-wrap justify-content-between">

                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <footer className="foote footer-alter">

                      <div className="row">
                        <div className="col-12">
                          <div className="footer-alter__inner appear-down mt-2">
                            <div className="row">
                              <div className="col-12 col-lg-8 col-xl-8 col-xxl-8">
                                <div className="footer-alter__content d-block">
                                  <div>
                                    <h3 className="title-animation fw-7 text-black">Enter Address</h3>
                                    <br />
                                  </div>

                                  <div className="checkout-m p-0">
                                    <div className="checkout-m__form">
                                      <form >
                                        <div className="input">
                                          <div className="input-single">
                                            <input
                                              type="text"
                                              name="check-name"
                                              placeholder="Enter address*"
                                              onChange={(e) => setaddr(e.target.value)} 
                                            />
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-wrap justify-content-around align-items-center mt-3">
                                    <button className="button-3 m-2"  onClick={() => go()}>
                                      <div class="display">
                                        <div id="msg">Overview</div>
                                      </div>
                                      <span></span>
                                      <span></span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </footer>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}
