import React, { useContext } from "react";
import {ImpC } from "../../Myontext";

export default function GetImpresiveToken() {
  const { rate } = useContext(ImpC);
  return (
    <>
      <section
        className="section h-chatbot"
        data-background="assets/images/h-chatbot-bg.png"
      >
        <div className="container">
          <div className="row gaper align-items-center">
            <div className="col-12 col-lg-5">
              <div className="section__content">
                <div className="s-thumb">
                  <img src="assets/images/wheel.png" alt="Image" />
                </div>
                
                <h2 className="title title-animation">
                  Giveaway 
                </h2>
                <h4 className="my-2 text-white"><b>Impresive Token</b></h4>
                <p>
                  We're thrilled to announce a special giveaway of Impresive
                  Tokens to our amazing community! As a thank you for your
                  ongoing support and enthusiasm
                </p>
                <h4 className="my-2 text-white"><b>How to Participate</b></h4>
                <ul>
                  <li className="m-1">
                    <ul className="m-1">
                      <li className="m-1">- Twitter follow</li>
                      <li className="m-1">- Twitter pinned image with photo retweet</li>
                      <li className="m-1">- Instagram page follow </li>
                      <li className="m-1">- Telegram join </li>
                      <li className="m-1">- YouTube channel follow</li>
                    </ul>
                  </li>
                </ul>
                <div className="section__content-cta">
                  <a className="btn btn--primary">
                    Get started
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 offset-lg-1">
              <div className="h-chatbot__thumb text-start text-lg-end reveal-img parallax-img">
                <img src="assets/images/giveway.png" alt="Image" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
