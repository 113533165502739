import React from "react";

const Ecosystem = () => {
  return (
    <div>
      <section
        className="section h-s-case pb-0"
        data-background="assets/images/s-j-bg.png" id="Ecosystem"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-10">
              <div className="section__header text-center">
                <h2 className="mt-12 title title-animation">Ecosystem</h2>
                <p>
                  In the Impresive Token Ecosystem, there are various tools and
                  platforms leveraging artificial intelligence (AI) to optimize
                  decentralized finance (DeFi) protocols. These tools aim to
                  enhance trading strategies, risk management, yield farming,
                  and data analytics within the DeFi space. Here are some
                  examples of Crypto AI DeFi tools
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="h-s-case-wrapper">
          <div className="h-s-case-single h-s-case-single-active">
            <div className="thumb">
              <img src="assets/images/automated_trading_bots.png" alt="Image" />
            </div>
            <div className="h-case-content">
              <div className="case-title">
                <h2 className="light-title">
                  <a href="">Automated Trading</a>
                </h2>
              </div>
              <div className="h-c-continent">
                <p className="text-justify">
                  AI-powered trading bots execute buy and sell orders
                  automatically based on predefined parameters and market
                  conditions. These bots leverage machine learning algorithms to
                  analyze market data, identify trading opportunities, and
                  execute trades across different DeFi platforms.
                </p>
                <a href="" className="cta">
                  <span className="arrow" />
                </a>
              </div>
            </div>
          </div>
          <div className="h-s-case-single">
            <div className="thumb">
              <img src="assets/images/risk_management_solutions.png" alt="Image" />
            </div>
            <div className="h-case-content">
              <div className="case-title">
                <h2 className="light-title">
                  <a href="">Risk Management</a>
                </h2>
              </div>
              <div className="h-c-continent">
                <p className="text-justify">
                  AI-driven risk management tools assess and mitigate risks
                  associated with DeFi protocols, such as smart contract
                  vulnerabilities, liquidity risks, and market volatility. These
                  tools provide real-time monitoring, risk assessment, and
                  automated responses to minimize potential losses.
                </p>
                <a href="" className="cta">
                  <span className="arrow" />
                </a>
              </div>
            </div>
          </div>
          <div className="h-s-case-single">
            <div className="thumb">
              <img src="assets/images/yield_optimization.png" alt="Image" />
            </div>
            <div className="h-case-content">
              <div className="case-title">
                <h2 className="light-title">
                  <a href="">Yield Optimization</a>
                </h2>
              </div>
              <div className="h-c-continent">
                <p className="text-justify">
                  AI algorithms optimize yield farming strategies by dynamically
                  reallocating assets to maximize returns while minimizing
                  risks. These platforms analyze market data, liquidity pools,
                  and yield opportunities to optimize portfolio allocations and
                  maximize profitability for users.
                </p>
                <a href="" className="cta">
                  <span className="arrow" />
                </a>
              </div>
            </div>
          </div>
          <div className="h-s-case-single">
            <div className="thumb">
              <img src="assets/images/e_commerce.png" alt="Image" />
            </div>
            <div className="h-case-content">
              <div className="case-title">
                <h2 className="light-title">
                  <a href="">E-Commerce</a>
                </h2>
              </div>
              <div className="h-c-continent">
                <p className="text-justify">
                  AI-based crypto e-commerce platforms offer personalized
                  experiences, enhanced security through fraud detection, and
                  operational efficiency via predictive analytics, driving
                  growth and customer satisfaction in the evolving landscape of
                  online cryptocurrency transactions.
                </p>
                <a href="" className="cta">
                  <span className="arrow" />
                </a>
              </div>
            </div>
          </div>
          <div className="h-s-case-single">
            <div className="thumb">
              <img src="assets/images/portfolio_management.png" alt="Image" />
            </div>
            <div className="h-case-content">
              <div className="case-title">
                <h2 className="light-title">
                  <a href="">Portfolio Manage</a>
                </h2>
              </div>
              <div className="h-c-continent">
                <p className="text-justify">
                  AI-powered portfolio management tools help users manage their
                  DeFi assets efficiently by providing real-time portfolio
                  tracking, performance analysis, and automated rebalancing.
                  These tools optimize portfolio allocations, minimize risks,
                  and maximize returns based on users' investment goals and risk
                  preferences.
                </p>
                <a href="" className="cta">
                  <span className="arrow" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Ecosystem;
