import React, { useContext, useEffect, useState } from "react";
import "./styles.css";
import { ImpC } from "../../Myontext";
import ProgressbarCircle from "./ProgressbarCircle";
import MyTimer from "./MyTimer";
import { Link, useNavigate } from "react-router-dom";

var start = new Date();
var now = new Date();

start.setHours(17, 35, 0); // inseret time here

if (now > start) {
  // too late, go to tomorrow
  start.setDate(start.getDate() + 1);
}

export default function DashboardHero() {
  const {
    account, toastError, toastSuccess,
    smuser, getsmcs, connectMetamask,
    copyaddress, dbuser,
    rate, stakcontract
  } = useContext(ImpC);
  const [loading, setloading] = useState(false);
  const [hash, sethash] = useState(null);
  const [ac_claim, setac_claim] = useState(false);
  const [n_date, setn_date] = useState(new Date());

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setInterval(() => setProgress(Math.floor(Math.random() * 100) + 1), 2000);
  }, []);
  const stakeNow = async () => {
    if (!account) {
      connectMetamask();
      toastError("Connect first");
      setloading(false);
      return;
    }
    setloading(true);
    try {
      var stk = await stakcontract.claimtoken();
      const receipt = await stk.wait();
      sethash(receipt.transactionHash);
      toastSuccess("Transaction done");
      // getuserDB();
      getsmcs();
      setloading(false);
    } catch (error) {
      console.log(error);
      toastError("Transaction declined");
      setloading(false);
      return;
    }
    // navigate("/dashboard");
  };
  const navigate = useNavigate();

  const handleClick = () => {
    // Redirect to home page
    navigate('/');

    // Scroll to 'stake' section after a short delay
    setTimeout(() => {
      const stakeSection = document.getElementById('Stake');
      if (stakeSection) {
        stakeSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100); // Adjust the delay as needed
  };
  useEffect(() => {
    if (smuser) {
      if (smuser._lastclaim !== 0) {
        const currentDate = new Date();
        const newDate = new Date(smuser._lastclaim * 1000);
        // Add 24 hours to the new date
        newDate.setDate(newDate.getDate() + 1);
        setn_date(newDate)
        const isDateGreaterThanCurrent = currentDate > newDate;
        setac_claim(isDateGreaterThanCurrent)
      }
    }
  }, [smuser])
  const [counter, setcounter] = useState(0);

  useEffect(() => {
    if (smuser === null) {
      return
    }
    // console.log("data[0].starttimedata[0].starttimedata[0].starttime", data);
    // console.log("data[0].dailyreward",data[0].dailyreward,Number(data[0].duration));
    const intervalId = setInterval(() => {
      const firstPaymentDate = new Date(Number(smuser._lastclaim) * 1000); //replace with your first payment date
      // const firstPaymentDate = new Date(Number(1713831703) * 1000); //replace with your first payment date
      const lsta = Number(smuser.d_reward);
      const aw = (lsta / 86400).toFixed(14);
      const currentDate = new Date();
      const timePassed = currentDate.getTime() - firstPaymentDate.getTime();
      const totalSeconds = Math.floor(timePassed / 1000);
      const totalAmount = totalSeconds * aw; //replace with your payment amount per second
      // const tilldate = Number(1713918103)* 1000 + 24 * 60 * 60 * 1000;
      const tilldate = Number(smuser._lastclaim) * 1000 + 24 * 60 * 60 * 1000;
      const time = Date.parse(new Date(tilldate)) - Date.parse(new Date());
      // console.log("aw",aw);
      setcounter(totalAmount);

      // if (Math.sign(time) === -1 || time.toString() === "NaN") {
      //   // const currentDate = new Date(Number(1713918103) * 1000);
      //   const currentDate = new Date(tilldate);
      //   const timePassed = currentDate.getTime() - firstPaymentDate.getTime();
      //   const totalSeconds = Math.floor(timePassed / 1000);
      //   const totalAmount = totalSeconds * aw; //replace with your payment amount per second
      //   setcounter(totalAmount);
      //   // dataa.push(totalAmount)

      // } else {
      //   // dataa.push(totalAmount)
      //   setcounter(totalAmount);
      // }


      // setcounter(dataa);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [smuser]);
  const time = new Date();
  time.setSeconds(time.getSeconds() + 600); // 10 minutes timer
  const targetDateTime = "2024-12-31T23:59:59";
  return (
    <>
      <div className="row justify-content-center my-3">

        <div className="col-12 d-flex flex-wrap justify-content-between">
          <p className="green-border mt-lg-5 my-1 notes">
            <span className="green-bdge text-black"><b>Name - </b> {dbuser !== null ? dbuser.name : ""}</span>
          </p>
          {ac_claim ? <>
            {!loading ?
              <button class="button m-4" onClick={() => stakeNow()}>Claim Reward</button> :
              <img src="assets/images/loader.gif" alt="" style={{ "width": "100px" }} />}
          </> : ''}
          <p className="green-border mt-lg-5 my-1 notes">
          <span className="green-bdge text-black"><b>&nbsp;&nbsp;{counter} IMP</b> - Pending Reward</span> 
          </p>
          {/* <p className="green-border-1 mt-lg-5 my-1 mx-2 notes-1 w-100">
            <span className="green-bdge text-black"><b>Live Rate -</b> ${rate}</span>{" "}
          </p> */}

        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <footer className="foote footer-alter">

            <div className="row">
              <div className="col-12">
                <div className="footer-alter__inner appear-down mt-2">
                  <div className="row">
                    <div className="col-12 col-lg-8 col-xl-8 col-xxl-8">
                      <div className="footer-alter__content d-block">
                        <div>
                          <h3 className="title-animation fw-7 text-black">Address</h3>
                          <br />
                          <p className="text-black text-break" onClick={() => copyaddress(account)}>
                            {account}{" "}
                            <i className="fa fa-copy" />
                          </p>
                        </div>
                        <br />
                        <div>
                          <h3 className="title-animation fw-7 text-black">
                            Referral Address
                          </h3>
                          <br />
                          <p className="text-black text-break" onClick={() => copyaddress(smuser.ref_address)}>
                            {smuser !== null ? smuser.ref_address : ''}{" "}
                            <i className="fa fa-copy" />
                          </p>
                        </div>
                        <div className="d-flex flex-wrap align-items-center mt-3">

                          <div className="voltage-button">
                            <button
                              className="button-4 m-1"
                              onClick={handleClick}
                            >
                              Stake IMP
                            </button>
                            <svg
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              viewBox="0 0 234.6 61.3"
                              preserveAspectRatio="none"
                              xmlSpace="preserve"
                            >
                              <filter id="glow">
                                <feGaussianBlur
                                  className="blur"
                                  result="coloredBlur"
                                  stdDeviation={2}
                                />
                                <feTurbulence
                                  type="fractalNoise"
                                  baseFrequency="0.075"
                                  numOctaves="0.3"
                                  result="turbulence"
                                />
                                <feDisplacementMap
                                  in="SourceGraphic"
                                  in2="turbulence"
                                  scale={30}
                                  xChannelSelector="R"
                                  yChannelSelector="G"
                                  result="displace"
                                />
                                <feMerge>
                                  <feMergeNode in="coloredBlur" />
                                  <feMergeNode in="coloredBlur" />
                                  <feMergeNode in="coloredBlur" />
                                  <feMergeNode in="displace" />
                                  <feMergeNode in="SourceGraphic" />
                                </feMerge>
                              </filter>
                              <path
                                className="voltage line-1"
                                d="m216.3 51.2c-3.7 0-3.7-1.1-7.3-1.1-3.7 0-3.7 6.8-7.3 6.8-3.7 0-3.7-4.6-7.3-4.6-3.7 0-3.7 3.6-7.3 3.6-3.7 0-3.7-0.9-7.3-0.9-3.7 0-3.7-2.7-7.3-2.7-3.7 0-3.7 7.8-7.3 7.8-3.7 0-3.7-4.9-7.3-4.9-3.7 0-3.7-7.8-7.3-7.8-3.7 0-3.7-1.1-7.3-1.1-3.7 0-3.7 3.1-7.3 3.1-3.7 0-3.7 10.9-7.3 10.9-3.7 0-3.7-12.5-7.3-12.5-3.7 0-3.7 4.6-7.3 4.6-3.7 0-3.7 4.5-7.3 4.5-3.7 0-3.7 3.6-7.3 3.6-3.7 0-3.7-10-7.3-10-3.7 0-3.7-0.4-7.3-0.4-3.7 0-3.7 2.3-7.3 2.3-3.7 0-3.7 7.1-7.3 7.1-3.7 0-3.7-11.2-7.3-11.2-3.7 0-3.7 3.5-7.3 3.5-3.7 0-3.7 3.6-7.3 3.6-3.7 0-3.7-2.9-7.3-2.9-3.7 0-3.7 8.4-7.3 8.4-3.7 0-3.7-14.6-7.3-14.6-3.7 0-3.7 5.8-7.3 5.8-2.2 0-3.8-0.4-5.5-1.5-1.8-1.1-1.8-2.9-2.9-4.8-1-1.8 1.9-2.7 1.9-4.8 0-3.4-2.1-3.4-2.1-6.8s-9.9-3.4-9.9-6.8 8-3.4 8-6.8c0-2.2 2.1-2.4 3.1-4.2 1.1-1.8 0.2-3.9 2-5 1.8-1 3.1-7.9 5.3-7.9 3.7 0 3.7 0.9 7.3 0.9 3.7 0 3.7 6.7 7.3 6.7 3.7 0 3.7-1.8 7.3-1.8 3.7 0 3.7-0.6 7.3-0.6 3.7 0 3.7-7.8 7.3-7.8h7.3c3.7 0 3.7 4.7 7.3 4.7 3.7 0 3.7-1.1 7.3-1.1 3.7 0 3.7 11.6 7.3 11.6 3.7 0 3.7-2.6 7.3-2.6 3.7 0 3.7-12.9 7.3-12.9 3.7 0 3.7 10.9 7.3 10.9 3.7 0 3.7 1.3 7.3 1.3 3.7 0 3.7-8.7 7.3-8.7 3.7 0 3.7 11.5 7.3 11.5 3.7 0 3.7-1.4 7.3-1.4 3.7 0 3.7-2.6 7.3-2.6 3.7 0 3.7-5.8 7.3-5.8 3.7 0 3.7-1.3 7.3-1.3 3.7 0 3.7 6.6 7.3 6.6s3.7-9.3 7.3-9.3c3.7 0 3.7 0.2 7.3 0.2 3.7 0 3.7 8.5 7.3 8.5 3.7 0 3.7 0.2 7.3 0.2 3.7 0 3.7-1.5 7.3-1.5 3.7 0 3.7 1.6 7.3 1.6s3.7-5.1 7.3-5.1c2.2 0 0.6 9.6 2.4 10.7s4.1-2 5.1-0.1c1 1.8 10.3 2.2 10.3 4.3 0 3.4-10.7 3.4-10.7 6.8s1.2 3.4 1.2 6.8 1.9 3.4 1.9 6.8c0 2.2 7.2 7.7 6.2 9.5-1.1 1.8-12.3-6.5-14.1-5.5-1.7 0.9-0.1 6.2-2.2 6.2z"
                                fill="transparent"
                                stroke="#fff"
                              />
                              <path
                                className="voltage line-2"
                                d="m216.3 52.1c-3 0-3-0.5-6-0.5s-3 3-6 3-3-2-6-2-3 1.6-6 1.6-3-0.4-6-0.4-3-1.2-6-1.2-3 3.4-6 3.4-3-2.2-6-2.2-3-3.4-6-3.4-3-0.5-6-0.5-3 1.4-6 1.4-3 4.8-6 4.8-3-5.5-6-5.5-3 2-6 2-3 2-6 2-3 1.6-6 1.6-3-4.4-6-4.4-3-0.2-6-0.2-3 1-6 1-3 3.1-6 3.1-3-4.9-6-4.9-3 1.5-6 1.5-3 1.6-6 1.6-3-1.3-6-1.3-3 3.7-6 3.7-3-6.4-6-6.4-3 2.5-6 2.5h-6c-3 0-3-0.6-6-0.6s-3-1.4-6-1.4-3 0.9-6 0.9-3 4.3-6 4.3-3-3.5-6-3.5c-2.2 0-3.4-1.3-5.2-2.3-1.8-1.1-3.6-1.5-4.6-3.3s-4.4-3.5-4.4-5.7c0-3.4 0.4-3.4 0.4-6.8s2.9-3.4 2.9-6.8-0.8-3.4-0.8-6.8c0-2.2 0.3-4.2 1.3-5.9 1.1-1.8 0.8-6.2 2.6-7.3 1.8-1 5.5-2 7.7-2 3 0 3 2 6 2s3-0.5 6-0.5 3 5.1 6 5.1 3-1.1 6-1.1 3-5.6 6-5.6 3 4.8 6 4.8 3 0.6 6 0.6 3-3.8 6-3.8 3 5.1 6 5.1 3-0.6 6-0.6 3-1.2 6-1.2 3-2.6 6-2.6 3-0.6 6-0.6 3 2.9 6 2.9 3-4.1 6-4.1 3 0.1 6 0.1 3 3.7 6 3.7 3 0.1 6 0.1 3-0.6 6-0.6 3 0.7 6 0.7 3-2.2 6-2.2 3 4.4 6 4.4 3-1.7 6-1.7 3-4 6-4 3 4.7 6 4.7 3-0.5 6-0.5 3-0.8 6-0.8 3-3.8 6-3.8 3 6.3 6 6.3 3-4.8 6-4.8 3 1.9 6 1.9 3-1.9 6-1.9 3 1.3 6 1.3c2.2 0 5-0.5 6.7 0.5 1.8 1.1 2.4 4 3.5 5.8 1 1.8 0.3 3.7 0.3 5.9 0 3.4 3.4 3.4 3.4 6.8s-3.3 3.4-3.3 6.8 4 3.4 4 6.8c0 2.2-6 2.7-7 4.4-1.1 1.8 1.1 6.7-0.7 7.7-1.6 0.8-4.7-1.1-6.8-1.1z"
                                fill="transparent"
                                stroke="#fff"
                              />
                            </svg>
                            <div className="dots">
                              <div className="dot dot-1" />
                              <div className="dot dot-2" />
                              <div className="dot dot-3" />
                              <div className="dot dot-4" />
                              <div className="dot dot-5" />
                            </div>
                          </div>
                          <div className="voltage-button">
                            <a
                              className="button-4 m-1"
                              href="https://pancakeswap.finance/swap?chain=bsc&inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0xA7358433cDce3416F15764B1DA667A9Eacb0dd01"
                              target="_blank"
                            >
                              Buy IMP
                            </a>
                            <svg
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              viewBox="0 0 234.6 61.3"
                              preserveAspectRatio="none"
                              xmlSpace="preserve"
                            >
                              <filter id="glow">
                                <feGaussianBlur
                                  className="blur"
                                  result="coloredBlur"
                                  stdDeviation={2}
                                />
                                <feTurbulence
                                  type="fractalNoise"
                                  baseFrequency="0.075"
                                  numOctaves="0.3"
                                  result="turbulence"
                                />
                                <feDisplacementMap
                                  in="SourceGraphic"
                                  in2="turbulence"
                                  scale={30}
                                  xChannelSelector="R"
                                  yChannelSelector="G"
                                  result="displace"
                                />
                                <feMerge>
                                  <feMergeNode in="coloredBlur" />
                                  <feMergeNode in="coloredBlur" />
                                  <feMergeNode in="coloredBlur" />
                                  <feMergeNode in="displace" />
                                  <feMergeNode in="SourceGraphic" />
                                </feMerge>
                              </filter>
                              <path
                                className="voltage line-1"
                                d="m216.3 51.2c-3.7 0-3.7-1.1-7.3-1.1-3.7 0-3.7 6.8-7.3 6.8-3.7 0-3.7-4.6-7.3-4.6-3.7 0-3.7 3.6-7.3 3.6-3.7 0-3.7-0.9-7.3-0.9-3.7 0-3.7-2.7-7.3-2.7-3.7 0-3.7 7.8-7.3 7.8-3.7 0-3.7-4.9-7.3-4.9-3.7 0-3.7-7.8-7.3-7.8-3.7 0-3.7-1.1-7.3-1.1-3.7 0-3.7 3.1-7.3 3.1-3.7 0-3.7 10.9-7.3 10.9-3.7 0-3.7-12.5-7.3-12.5-3.7 0-3.7 4.6-7.3 4.6-3.7 0-3.7 4.5-7.3 4.5-3.7 0-3.7 3.6-7.3 3.6-3.7 0-3.7-10-7.3-10-3.7 0-3.7-0.4-7.3-0.4-3.7 0-3.7 2.3-7.3 2.3-3.7 0-3.7 7.1-7.3 7.1-3.7 0-3.7-11.2-7.3-11.2-3.7 0-3.7 3.5-7.3 3.5-3.7 0-3.7 3.6-7.3 3.6-3.7 0-3.7-2.9-7.3-2.9-3.7 0-3.7 8.4-7.3 8.4-3.7 0-3.7-14.6-7.3-14.6-3.7 0-3.7 5.8-7.3 5.8-2.2 0-3.8-0.4-5.5-1.5-1.8-1.1-1.8-2.9-2.9-4.8-1-1.8 1.9-2.7 1.9-4.8 0-3.4-2.1-3.4-2.1-6.8s-9.9-3.4-9.9-6.8 8-3.4 8-6.8c0-2.2 2.1-2.4 3.1-4.2 1.1-1.8 0.2-3.9 2-5 1.8-1 3.1-7.9 5.3-7.9 3.7 0 3.7 0.9 7.3 0.9 3.7 0 3.7 6.7 7.3 6.7 3.7 0 3.7-1.8 7.3-1.8 3.7 0 3.7-0.6 7.3-0.6 3.7 0 3.7-7.8 7.3-7.8h7.3c3.7 0 3.7 4.7 7.3 4.7 3.7 0 3.7-1.1 7.3-1.1 3.7 0 3.7 11.6 7.3 11.6 3.7 0 3.7-2.6 7.3-2.6 3.7 0 3.7-12.9 7.3-12.9 3.7 0 3.7 10.9 7.3 10.9 3.7 0 3.7 1.3 7.3 1.3 3.7 0 3.7-8.7 7.3-8.7 3.7 0 3.7 11.5 7.3 11.5 3.7 0 3.7-1.4 7.3-1.4 3.7 0 3.7-2.6 7.3-2.6 3.7 0 3.7-5.8 7.3-5.8 3.7 0 3.7-1.3 7.3-1.3 3.7 0 3.7 6.6 7.3 6.6s3.7-9.3 7.3-9.3c3.7 0 3.7 0.2 7.3 0.2 3.7 0 3.7 8.5 7.3 8.5 3.7 0 3.7 0.2 7.3 0.2 3.7 0 3.7-1.5 7.3-1.5 3.7 0 3.7 1.6 7.3 1.6s3.7-5.1 7.3-5.1c2.2 0 0.6 9.6 2.4 10.7s4.1-2 5.1-0.1c1 1.8 10.3 2.2 10.3 4.3 0 3.4-10.7 3.4-10.7 6.8s1.2 3.4 1.2 6.8 1.9 3.4 1.9 6.8c0 2.2 7.2 7.7 6.2 9.5-1.1 1.8-12.3-6.5-14.1-5.5-1.7 0.9-0.1 6.2-2.2 6.2z"
                                fill="transparent"
                                stroke="#fff"
                              />
                              <path
                                className="voltage line-2"
                                d="m216.3 52.1c-3 0-3-0.5-6-0.5s-3 3-6 3-3-2-6-2-3 1.6-6 1.6-3-0.4-6-0.4-3-1.2-6-1.2-3 3.4-6 3.4-3-2.2-6-2.2-3-3.4-6-3.4-3-0.5-6-0.5-3 1.4-6 1.4-3 4.8-6 4.8-3-5.5-6-5.5-3 2-6 2-3 2-6 2-3 1.6-6 1.6-3-4.4-6-4.4-3-0.2-6-0.2-3 1-6 1-3 3.1-6 3.1-3-4.9-6-4.9-3 1.5-6 1.5-3 1.6-6 1.6-3-1.3-6-1.3-3 3.7-6 3.7-3-6.4-6-6.4-3 2.5-6 2.5h-6c-3 0-3-0.6-6-0.6s-3-1.4-6-1.4-3 0.9-6 0.9-3 4.3-6 4.3-3-3.5-6-3.5c-2.2 0-3.4-1.3-5.2-2.3-1.8-1.1-3.6-1.5-4.6-3.3s-4.4-3.5-4.4-5.7c0-3.4 0.4-3.4 0.4-6.8s2.9-3.4 2.9-6.8-0.8-3.4-0.8-6.8c0-2.2 0.3-4.2 1.3-5.9 1.1-1.8 0.8-6.2 2.6-7.3 1.8-1 5.5-2 7.7-2 3 0 3 2 6 2s3-0.5 6-0.5 3 5.1 6 5.1 3-1.1 6-1.1 3-5.6 6-5.6 3 4.8 6 4.8 3 0.6 6 0.6 3-3.8 6-3.8 3 5.1 6 5.1 3-0.6 6-0.6 3-1.2 6-1.2 3-2.6 6-2.6 3-0.6 6-0.6 3 2.9 6 2.9 3-4.1 6-4.1 3 0.1 6 0.1 3 3.7 6 3.7 3 0.1 6 0.1 3-0.6 6-0.6 3 0.7 6 0.7 3-2.2 6-2.2 3 4.4 6 4.4 3-1.7 6-1.7 3-4 6-4 3 4.7 6 4.7 3-0.5 6-0.5 3-0.8 6-0.8 3-3.8 6-3.8 3 6.3 6 6.3 3-4.8 6-4.8 3 1.9 6 1.9 3-1.9 6-1.9 3 1.3 6 1.3c2.2 0 5-0.5 6.7 0.5 1.8 1.1 2.4 4 3.5 5.8 1 1.8 0.3 3.7 0.3 5.9 0 3.4 3.4 3.4 3.4 6.8s-3.3 3.4-3.3 6.8 4 3.4 4 6.8c0 2.2-6 2.7-7 4.4-1.1 1.8 1.1 6.7-0.7 7.7-1.6 0.8-4.7-1.1-6.8-1.1z"
                                fill="transparent"
                                stroke="#fff"
                              />
                            </svg>
                            <div className="dots">
                              <div className="dot dot-1" />
                              <div className="dot dot-2" />
                              <div className="dot dot-3" />
                              <div className="dot dot-4" />
                              <div className="dot dot-5" />
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-2 col-xl-2 col-xxl-2 d-flex flex-wrap justify-content-center align-items-center">
                      <div className="mb-2" style={{ width: "150px" }}>
                        <ProgressbarCircle />
                      </div>
                      <MyTimer targetDateTime={n_date} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}
